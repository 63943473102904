import classNames from 'classnames'
import React, {
  FC,
  InputHTMLAttributes,
  LabelHTMLAttributes,
  SelectHTMLAttributes,
  TextareaHTMLAttributes,
} from 'react'

type BaseProps = { label?: string }

type BaseInputProps = BaseProps & LabelHTMLAttributes<HTMLLabelElement>

const BaseInput: FC<BaseInputProps> = ({ className, children, label, ...props }) => {
  return (
    <label {...props} className={classNames(className, 'block font-normal')}>
      <p className="block body-1 mb-2">{label}</p>
      {children}
    </label>
  )
}

type TextInputProps = BaseProps & InputHTMLAttributes<HTMLInputElement>
export const TextInput: FC<TextInputProps> = ({ className, name, label, ...props }) => {
  return (
    <BaseInput label={label} className={className}>
      <input
        {...props}
        className="appearance-none rounded border-[0.5px] px-2 py-1 border-gray-400 w-full min-h-[32px] body-2 placeholder:text-secondary"
      />
    </BaseInput>
  )
}

type TextAreaProps = BaseProps & TextareaHTMLAttributes<HTMLTextAreaElement>
export const TextArea: FC<TextAreaProps> = ({ className, name, label, ...props }) => {
  return (
    <BaseInput label={label} className={className}>
      <textarea
        {...props}
        className="appearance-none rounded border-[0.5px] px-2 py-2 border-gray-400 w-full min-h-[32px] body-2 placeholder:text-secondary"
      />
    </BaseInput>
  )
}

type SelectProps = BaseProps & SelectHTMLAttributes<HTMLSelectElement>
export const Select: FC<SelectProps> = ({ className, name, label, ...props }) => {
  return (
    <BaseInput label={label} className={className}>
      <select
        {...props}
        className="appearance-none rounded border-[0.5px] px-2 py-1 border-gray-400 w-full min-h-[32px] body-2 placeholder:text-secondary"
      />
    </BaseInput>
  )
}
