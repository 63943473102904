import { HeadFC, PageProps } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React, { FormEvent, useState } from 'react'
import { useRef } from 'react'
import { BsArrowBarLeft } from 'react-icons/bs'
import { MdOutlineMarkEmailRead } from 'react-icons/md'
import { useIntl } from 'react-intl'

import Button from '../components/Button'
import { Select, TextArea, TextInput } from '../components/Inputs'
import { LanguageHeaders } from '../components/LanguageHeaders'
import Layout from '../components/Layout'
import Link from '../components/Link'
import { ColorsPrimary, ContactEmail, SubmitFormUrl } from '../constants'
import { useTranslate } from '../utils/translate'

const ContactPage = ({ location }: PageProps) => {
  const formRef = useRef<HTMLFormElement>(null)
  const params = new URLSearchParams(location.search)
  const locale = useIntl().locale
  const requestTypeParam = params.get('q') || null
  const [success, setSuccess] = useState<boolean | null>(null)
  const t = useTranslate()

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const data = {
      type: requestTypeParam || formRef.current?.type?.value,
      firstName: formRef.current?.firstName.value,
      lastName: formRef.current?.lastName.value,
      company: formRef.current?.company.value,
      email: formRef.current?.email.value,
      phoneNumber: formRef.current?.phoneNumber.value,
      vehicles: formRef.current?.vehicles.value,
      role: formRef.current?.role.value,
      currentTool: formRef.current?.currentTool.value,
      message: formRef.current?.message.value,
      language: locale,
    }
    ;(async () => {
      try {
        const response = await fetch(SubmitFormUrl, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        setSuccess(response.ok)
      } catch (e) {
        setSuccess(false)
      }
    })()
  }

  return (
    <Layout light noFooter noHeader>
      <main className="w-full h-screen grid lg:grid-cols-2 items-stretch">
        {success === true && (
          <section className="m-auto grid grid-flow-row gap-4">
            <MdOutlineMarkEmailRead size={40} color={ColorsPrimary} />
            <h4>{t('contact.success.title')}</h4>
            <Link className="flex items-center text-blue" to="/">
              <BsArrowBarLeft size={20} /> {t('backToWebsite')}
            </Link>
          </section>
        )}
        {success === false && (
          <section className="m-auto grid grid-flow-row gap-4">
            <h4 className="text-orange-600">{t('contact.fail.title')}</h4>
            <p className="body-1">{t('contact.fail.subtitle')}</p>
            <a className="body-1" href={`mailto:${ContactEmail}`}>
              {ContactEmail}
            </a>
          </section>
        )}
        {success === null && (
          <section className="max-h-screen w-full overflow-y-auto pb-20">
            <nav className="mt-8 ml-8">
              <Link className="flex items-center" to="/">
                <BsArrowBarLeft size={20} /> {t('backToWebsite')}
              </Link>
            </nav>
            <form
              ref={formRef}
              className="m-auto max-w-md col-span-2 lg:col-span-1 grid grid-flow-row gap-4 pt-20 px-4 md:px-0"
              onSubmit={handleSubmit}
            >
              <h2>{t('contact.title')}</h2>
              <p className="subtitle text-secondary">{t('contact.subtitle')}</p>

              <Select
                name="type"
                id="type"
                label={t('contact.inputs.requestType.label')}
                defaultValue={requestTypeParam || undefined}
              >
                <option value="free-account">{t('contact.inputs.requestType.values.free-account')}</option>
                <option value="demo">{t('contact.inputs.requestType.values.demo')}</option>
                <option value="quote">{t('contact.inputs.requestType.values.quote')}</option>
                <option value="other">{t('contact.inputs.requestType.values.other')}</option>
              </Select>

              <TextInput
                required
                name="firstName"
                id="firstName"
                label={t('contact.inputs.firstName.label')}
                placeholder={t('contact.inputs.firstName.placeholder')}
              />
              <TextInput
                required
                name="lastName"
                id="lastName"
                label={t('contact.inputs.lastName.label')}
                placeholder={t('contact.inputs.lastName.placeholder')}
              />
              <TextInput
                required
                name="company"
                id="company"
                label={t('contact.inputs.company.label')}
                placeholder={t('contact.inputs.company.placeholder')}
              />
              <TextInput
                required
                type="email"
                name="email"
                id="email"
                label={t('contact.inputs.email.label')}
                placeholder={t('contact.inputs.email.placeholder')}
              />
              <TextInput
                required
                name="phoneNumber"
                id="phoneNumber"
                label={t('contact.inputs.phoneNumber.label')}
                placeholder={t('contact.inputs.phoneNumber.placeholder')}
              />
              <Select name="vehicles" id="vehicles" label={t('contact.inputs.vehicles.label')}>
                <option value="1-5">1 - 5 {t('contact.inputs.vehicles.values.label')}</option>
                <option value="5-15">5 - 15 {t('contact.inputs.vehicles.values.label')}</option>
                <option value="15-50">15 - 50 {t('contact.inputs.vehicles.values.label')}</option>
                <option value="50-250">50 - 250 {t('contact.inputs.vehicles.values.label')}</option>
                <option value="250-1000">250 - 1000 {t('contact.inputs.vehicles.values.label')}</option>
                <option value="more">{t('contact.inputs.vehicles.values.more')}</option>
              </Select>
              <Select name="role" id="role" label={t('contact.inputs.role.label')}>
                <option value="fleet-management">{t('contact.inputs.role.values.fleet-management')}</option>
                <option value="it">{t('contact.inputs.role.values.it')}</option>
                <option value="purchasing">{t('contact.inputs.role.values.purchasing')}</option>
                <option value="finance">{t('contact.inputs.role.values.finance')}</option>
                <option value="hr">{t('contact.inputs.role.values.hr')}</option>
                <option value="other">{t('contact.inputs.role.values.other')}</option>
              </Select>
              <Select name="currentTool" id="currentTool" label={t('contact.inputs.currentTool.label')}>
                <option value="excel">{t('contact.inputs.currentTool.values.excel')}</option>
                <option value="access">{t('contact.inputs.currentTool.values.access')}</option>
                <option value="on-premise">{t('contact.inputs.currentTool.values.on-premise')}</option>
                <option value="cloud">{t('contact.inputs.currentTool.values.cloud')}</option>
                <option value="other">{t('contact.inputs.currentTool.values.other')}</option>
              </Select>
              <TextArea
                name="message"
                id="message"
                maxLength={2000}
                label={t('contact.inputs.message.label')}
                placeholder={t('contact.inputs.message.placeholder')}
              />
              <Button type="submit" color="primary">
                {t('contact.submit')}
              </Button>
            </form>
          </section>
        )}
        <div className="hidden lg:block max-h-screen overflow-hidden col-span-1 bg-gray-100">
          <StaticImage
            alt="van view"
            src="../images/vans_45.jpg"
            layout="constrained"
            placeholder="none"
            quality={90}
          />
        </div>
      </main>
    </Layout>
  )
}

export default ContactPage

export const Head: HeadFC = ({ pageContext }: any) => {
  const language = pageContext.language
  const translations: any = pageContext.translations
  return (
    <>
      <title>{translations['contact.meta.title']}</title>
      <meta name="description" content={translations['contact.meta.description']} />
      <meta name="robots" content="index, follow" />
      <LanguageHeaders pathname={pageContext.defaultPath} locale={language} />
    </>
  )
}
